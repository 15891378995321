<template>
  <div class="grid-column">
    <div v-if="isMobile" class="pm-18"></div>
    <h1 class="h1" v-html="t('h_1')"></h1>
    <!-- <div v-if="isMobile" class="pm-54"></div> -->
  </div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_2"></div>
  </div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>

  <div class="grid-column">
    <Promo
      v-if="newsSettings.image || newsSettings.video"
      class="promo_full_width"
      :src-pic="newsSettings.image.path"
      :src-pic-mobile="newsSettings.image.mobile_path"
      :src-video="newsSettings.video.path"
      :src-video-mobile="newsSettings.video.mobile_path"
      :is-video="newsSettings.show_video"
    />
  </div>

  <div v-if="!isMobile" class="grid-column">
    <Subscribe />
  </div>
  <div class="grid-column" v-for="(item, index) in normalizeNews" :key="index">
    <NewsCard
      v-if="item"
      :id="item.id"
      :title="item.title"
      :date="item.date"
      :image="item.preview ? item.preview.path : ''"
    />
    <div
      v-if="isMobile && normalizeNews.length - 1 !== index"
      class="grid-line"
    ></div>
  </div>

  <div class="grid-column grid-column_span_5">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="grid-inside grid-inside_place_center">
      <Button
        v-if="showButtonMore"
        class="btn-default btn-default-grey w-230"
        :class="{ 'btn-block': isMobile }"
        :msg="t('common_more_news')"
        @click="showMoreNews"
      />
    </div>
    <div v-if="isMobile" class="pm-18"></div>
  </div>

  <div v-if="isMobile" class="grid-column">
    <Subscribe />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapState, mapActions } from "vuex";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import Promo from "@/components/Promo.vue";
import Button from "@/components/Button.vue";
import NewsCard from "@/components/NewsCard.vue";
import Subscribe from "@/components/Subscribe.vue";

export default defineComponent({
  name: "News",
  components: {
    Promo,
    Button,
    NewsCard,
    Subscribe,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  computed: {
    ...mapState(["news", "newsStatus", "newsSettings"]),
    firstNews() {
      if (this.news.length) {
        return this.news[0];
      }
      return {};
    },
    normalizeNews() {
      const array = [];

      for (let index = 0; index < this.count; index++) {
        if (this.news && this.news.length) {
          array[index] = this.news[index];
        } else {
          array[index] = {};
        }
      }

      return array;
    },
    showButtonMore() {
      if (this.normalizeNews.length + 1 < this.news.length) {
        return true;
      }
      return false;
    },
  },
  watch: {
    locale() {
      this.fetchData(true);
      this.count = 8;
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      count: 8,
    };
  },
  methods: {
    ...mapActions(["fetchNews"]),
    fetchData(reload) {
      if (reload || this.newsStatus !== "success") {
        this.fetchNews();
      }
    },
    showMoreNews() {
      this.count = this.count + 5;
    },
  },
});
</script>

<style lang="scss" scoped>
@include mobile {
  .grid-column {
  }
}
@include desktop {
  .grid-column {
    &_span_5 {
      grid-column: span 5;
    }
    &:nth-of-type(6) {
      grid-column: span 3;
    }
    &:nth-of-type(9) {
      grid-column: span 5;
    }
    &:nth-of-type(10) {
      grid-row: span 2;
    }
    // &:nth-of-type(6) {
    //   grid-column: span 2;
    // }
    // &:nth-of-type(7) {
    //   grid-column: span 3;
    // }
    // &:nth-of-type(8) {
    //   grid-column: span 5;
    // }
    // &:nth-of-type(9) {
    //   grid-column: span 3;
    // }
    // &:nth-of-type(13) {
    //   grid-column: span 4;
    // }
  }
}
</style>

<i18n>
{
  "en": {
    "h_1": "News",
  },
  "ru": {
    "h_1": "Новости",
  },
  "ar": {
    "h_1": "الأخبار",
  }
}
</i18n>
