<template>
  <div class="subscribe" @submit="onSubmit">
    <form>
      <p>{{ t(`Subscribe1`) }}</p>
      <input
        v-if="!submitted"
        v-model="email"
        type="email"
        required
        :placeholder="t('common_email')"
      />
      <p v-if="!submitted" class="color-grey">{{ t(`Subscribe2`) }}</p>
      <div v-if="!submitted">
        <Button :disabled="loading" :msg="t('common_send')" />
      </div>
      <p v-if="submitted" class="h3 color-grey">{{ t(`Subscribe3`) }}</p>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ar from "@/locales/ar.json";

import { Subscribe } from "@/api/Subscribe";

import Button from "@/components/Button.vue";

export default defineComponent({
  name: "Subscribe",
  components: {
    Button,
  },
  props: {
    title: String,
    date: String,
    image: String,
    link: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      messages: {
        en,
        ru,
        ar,
      },
    });

    return { t, locale };
  },
  data() {
    return {
      submitted: false,
      loading: false,
      email: "",
    };
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        await Subscribe({ email: this.email });
        this.loading = false;
        this.submitted = true;
        this.email = "";
      } catch (error) {
        console.log(error);
        const { status } = error;
        if (status === 409) {
          this.submitted = true;
          this.email = "";
        }

        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
form {
  display: grid;
  gap: val(18) 0;
  @include mobile {
    gap: valM(18) 0;
  }
}
input {
  width: 100%;
  height: val(32);
  border: none;
  font-size: val(16);
  padding: 0 val(10);
  @include mobile {
    height: valM(32);
    font-size: valM(16);
    padding: 0 valM(10);
  }
}
</style>

<i18n>
{
  "en": {
    "Subscribe1": "Subscribe to our newsletter to receive our news",
    "Subscribe2": "By clicking the Send button you accept the terms of personal data processing",
    "Subscribe3": "Thank you!",
  },
  "ru": {
    "Subscribe1": "Подпишитесь на рассылку, чтобы получать наши новости",
    "Subscribe2": "Подписываясь на рассылку, Вы соглашаетесь на правила обработки личных данных",
    "Subscribe3": "Спасибо!",
  },
  "ar": {
    "Subscribe1": "اشترك في النشرة الإخبارية لدينا لتلقي أخبارنا",
    "Subscribe2": "بالاشتراك في النشرة الإخبارية ، فإنك توافق على قواعد معالجة البيانات الشخصية",
    "Subscribe3": "شكرا لك!",
  }
}
</i18n>
